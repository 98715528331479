<template>
  <div>
    <div class="uk-modal-header">
      <h1 class="uk-text-left uk-remove-margin">
        {{ $t("Sidebar.MediaLibrary") }}
      </h1>
      <a v-if="internalPath" class="uk-flex uk-text-left name">
        <span
          style="cursor: pointer; margin-left: -13px"
          uk-icon="icon: chevron-left; ratio: 2"
          @click="back"
        >
        </span>
        <h2
          class="uk-margin-remove uk-text-truncate uk-width-4-5"
          :class="{ name: internalPath }"
          @click="back"
        >
          {{ folderName }}
        </h2>
      </a>
      <h2
        v-else
        class="uk-text-left uk-margin-remove-top uk-text-truncate uk-width-4-5"
      >
        {{ folderName }}
      </h2>
    </div>
    <div class="uk-modal-body uk-padding-remove">
      <div v-if="isDataAvailable && !spinner">
        <br />
        <span> This folder is empty </span>
      </div>
      <span class="fixed" v-if="spinner" uk-spinner="ratio: 2"> </span>
      <div
        class="uk-grid-small uk-grid-match uk-padding-small fixed"
        uk-grid
        id="modal-list-media-items"
        uk-scrollspy="target: > div; cls: uk-animation-scale-up; offset-top: 100;"
        v-if="!spinner"
        uk-overflow-auto
        @scroll="infiniteScroll()"
      >
        <div
          v-for="(folder, i) in folders"
          :key="folder + i"
          class="
            uk-grid-item-match uk-width-1-6@l uk-width-1-4@m uk-width-1-2@s
          "
        >
          <MediaFolderCard
            :folder="folder"
            :is-modal="true"
            @click="onFolderClick(folder)"
          />
        </div>
        <div
          v-for="(item, i) in items"
          :key="i"
          class="
            uk-grid-item-match uk-width-1-6@l uk-width-1-4@m uk-width-1-2@s
          "
        >
          <MediaFileCard
            :is-modal="true"
            :selected-file="isSelected(item)"
            :item="item.node ? item.node : item"
            @click="onFileSelect(item.node ? item.node : item)"
          />
        </div>
      </div>
      <div
        v-if="loadMoreSpinner"
        class="uk-margin-small-top"
        uk-spinner="ratio: 1"
      ></div>
    </div>
    <div class="uk-modal-footer">
      <button
        class="uk-button uk-button-default uk-margin-small-right uk-modal-close"
        type="button"
        @click="cancel"
      >
        {{ $t("Actions.Cancel") }}
      </button>
      <button
        class="uk-button uk-button-default uk-margin-small-right uk-modal-close"
        type="button"
        :disabled="this.selectedFiles.length === 0"
        @click.stop="clear"
      >
        {{ $t("Actions.Clear") }}
      </button>
      <button
        v-if="!submit"
        :disabled="
          mode === 'folder'
            ? parentId === rootFolderID
              ? !selectedFolder
              : false
            : !selectedFiles.length && mediaClassFilter !== 'IMAGE'
        "
        class="
          uk-button uk-button-primary uk-margin-small-right uk-text-truncate
        "
        style="max-width: 200px"
        type="button"
        @click="select"
      >
        {{
          mode === "folder"
            ? moveButtonText
            : buttonText ||
              (selectedFiles.length !== 0 &&
                selectedFiles.length + " selected") ||
              $t("Actions.Select")
        }}
      </button>
      <button
        v-else
        class="uk-button uk-button-primary uk-margin-small-right"
        type="button"
        :disabled="true"
      >
        <span uk-spinner="ratio: 1"> </span>
      </button>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { MediaClass } from "../../utils/enums";

const getQueryMediaTypeFilter = (selectedMediaClassFilter) =>
  selectedMediaClassFilter
    ? [
        selectedMediaClassFilter === MediaClass.VIDEO ||
        selectedMediaClassFilter === MediaClass.LIVE
          ? "MediaBundle"
          : "Media",
      ]
    : ["MediaBundle", "Media"];

export default {
  name: "ModalArMediaPicker",
  components: {
    MediaFileCard: () => import("@/components/cards/MediaFileCard.vue"),
    MediaFolderCard: () => import("@/components/cards/MediaFolderCard.vue"),
  },
  props: {
    mediaClassFilter: {
      type: String,
      default: "NONE",
      validator: (c) => c in MediaClass || c === "NONE",
    },
    mode: { type: String, default: "file" },
    moveFolderId: { type: String, default: "" },
    submit: { type: Boolean, default: false },
    buttonText: { type: String, default: null },
    parentId: { type: String, default: "" },
    mediaList: { type: Array, required: false },
    selectedContentFiles: { type: Array, required: false },
  },
  data() {
    return {
      MediaClass,
      filter: false,
      type: ["MediaBundle", "Media"],
      spinner: false,
      loadMoreSpinner: false,
      fetching: false,
      selectedFolder: null,
      currentFolder: "",
      internalPath: "",
      selectedFiles: this.selectedContentFiles || [],
      after: null,
      cursor: null,
      selectedFolderName: "",
      orgId: this.$route.params.id,
      isFetching: false,
    };
  },
  computed: {
    ...mapState({
      mediaFolders: (state) => state.venom.org.mediaPickerFolders,
      mediaItems: (state) => state.venom.org.mediaPickerItems,
      currentFolderName: (state) =>
        state.venom.org.currentFolderAndParents?.folder?.name,
      orgName: (state) => state.venom.org.name,
      rootFolderID: (state) => state.venom.org.mediaLibraryRootFolderID,
    }),
    isDataAvailable() {
      return this.folders?.length === 0 && this.items?.length === 0;
    },
    folders() {
      return !this.mediaList
        ? this.mediaFolders?.filter((item) => item.id !== this.moveFolderId)
        : [];
    },
    items() {
      if (this.mediaList && this.mediaList.length > 0) {
        return this.mediaList;
      } else if (this.mode !== "folder") {
        return this.mediaItems?.filter(
          (item) =>
            (item.node.__typename === "MediaBundle" && item.node.masterVideo) ||
            item.node.__typename === "Media"
        );
      }
      return [];
    },
    folderName() {
      if (this.isFetching) return this.$t("Actions.Loading");
      return this.internalPath ? this.currentFolderName : this.orgName;
    },
    moveButtonText() {
      if (
        this.mode === "folder"
          ? this.parentId === this.rootFolderID
            ? !this.selectedFolder
            : false
          : !this.selectedFiles.length
      ) {
        return this.$t("Actions.Move");
      }
      return this.parentId === this.rootFolderID
        ? `${this.$t("Actions.Move")} -> ${this.selectedFolderName}`
        : this.selectedFolder
        ? `${this.$t("Actions.Move")} -> ${this.selectedFolderName}`
        : `${this.$t("Actions.Move")} -> Root`;
    },
  },
  mounted() {
    if (!this.mediaList) this.fetchMediaLibraryItems();
    this.selectedFiles = this.selectedContentFiles || [];
  },
  methods: {
    isSelected(item) {
      item = item.node ? item.node : item;
      let index = this.selectedFiles.findIndex(
        (element) => element.id === item.id
      );
      return index !== -1;
    },
    infiniteScroll() {
      const { scrollTop, scrollHeight, offsetHeight } = document.getElementById(
        "modal-list-media-items"
      );
      let bottomOfWindow = scrollTop + offsetHeight >= scrollHeight - 100;
      if (bottomOfWindow && !this.fetching && this.hasNextPage) {
        this.fetching = true;
        this.loadMore();
      }
    },
    async loadMore() {
      this.loadMoreSpinner = true;
      let response;
      if (!this.internalPath) {
        response = await this.$store.dispatch("fetchMediaLibraryItems", {
          orgId: this.orgId,
          mediaClass:
            this.mediaClassFilter !== "NONE" ? this.mediaClassFilter : null,
          type: this.type,
          filter: false,
          after: this.cursor,
          modal: true,
        });
      } else {
        const id = this.internalPath.split("/").pop();
        response = await this.$store.dispatch("fetchMediaLibraryFolderItems", {
          orgId: this.orgId,
          mediaClass:
            this.mediaClassFilter !== "NONE" ? this.mediaClassFilter : null,
          type: this.type,
          folderId: id,
          after: this.cursor,
          filter: false,
          modal: true,
        });
      }
      this.cursor = response.endCursor;
      this.hasNextPage = response.hasNextPage;
      this.loadMoreSpinner = false;
      this.fetching = false;
    },
    clear() {
      this.selectedFiles = [];
    },
    async fetchMediaLibraryItems() {
      this.spinner = true;
      let orgid =
        this.orgId && this.orgId === this.$route.params.id
          ? this.orgId
          : this.$route.params.id;
      if (this.mediaClassFilter === "NONE") {
        await this.$store.dispatch("fetchMediaLibraryItems", {
          orgId: orgid,
          type: this.type,
          filter: this.filter,
          modal: true,
        });
      } else {
        this.type = getQueryMediaTypeFilter(this.mediaClassFilter);
        const response = await this.$store.dispatch("fetchMediaLibraryItems", {
          orgId: this.orgId,
          mediaClass: this.mediaClassFilter,
          type: this.type,
          filter: this.filter,
          modal: true,
          after: this.after,
        });
        this.cursor = response?.endCursor;
        this.hasNextPage = response?.hasNextPage;
      }
      this.spinner = false;
    },
    async onFolderClick(folder) {
      this.internalPath = `${this.internalPath}/${folder.id}`;
      this.isFetching = true;
      const response = await this.$store.dispatch(
        "fetchMediaLibraryFolderItems",
        {
          orgId: this.orgId,
          type: this.type,
          folderId: folder.id,
          modal: true,
          mediaClass:
            this.mediaClassFilter !== "NONE" ? this.mediaClassFilter : null,
        }
      );
      this.isFetching = false;
      this.selectedFolder = folder.id;
      this.selectedFolderName = folder.name;
      this.cursor = response.endCursor;
      this.hasNextPage = response.hasNextPage;
    },
    async onFileSelect(item) {
      //2 possible scenarios here
      //  1-user selected it.
      //  2-user deselected it.
      //first we need to check if its already there or not...if its there ,delete it...not then add it.
      let index = this.selectedFiles.findIndex(
        (element) => element.id === item.id
      );
      if (index !== -1) {
        //we need to remove
        this.selectedFiles = this.selectedFiles
          .slice(0, index)
          .concat(
            this.selectedFiles.slice(index + 1, this.selectedFiles.length)
          );
      } else {
        // we need to add
        this.selectedFiles.push(item);
      }
    },
    async select() {
      this.$emit(
        "selectArs",
        this.mode === "folder"
          ? !this.selectedFolder
            ? this.rootFolderID
            : this.selectedFolder
          : this.selectedFiles
      );
      this.internalPath = "";
      this.selectedFolderName = "";
      this.selectedFolder = "";
      this.selectedFiles = [];
      if (!this.mediaList) this.fetchMediaLibraryItems();
    },
    async back() {
      if (this.internalPath) {
        const parts = this.internalPath.split("/");
        parts.pop();
        this.internalPath = parts.join("/");
        if (this.internalPath) {
          await this.$store.dispatch("fetchMediaLibraryFolderItems", {
            orgId: this.orgId,
            type: this.type,
            folderId: parts[parts.length - 1],
            modal: true,
            mediaClass:
              this.mediaClassFilter !== "NONE" ? this.mediaClassFilter : null,
          });
          this.selectedFolder = parts[parts.length - 1];
          this.selectedFolderName = this.currentFolderName;
          //   this.selectedFiles = [];
        } else {
          this.fetchMediaLibraryItems();
          this.selectedFolderName = "";
          this.selectedFolder = "";
          //   this.selectedFiles = [];
        }
      }
    },
    cancel() {
      this.$emit("cancel");
      this.internalPath = "";
      this.selectedFolderName = "";
      this.selectedFolder = "";
      this.selectedFiles = [];
      if (!this.mediaList) this.fetchMediaLibraryItems();
    },
  },
  watch: {
    mediaClassFilter() {
      this.fetchMediaLibraryItems();
    },
    selectedContentFiles() {
      this.selectedFiles = this.selectedContentFiles;
    },
  },
};
</script>

<style lang="scss" scoped>
.name {
  cursor: pointer;
  text-decoration: none;
  color: var(--app-primary-color);
}
.name:hover {
  color: var(--text-color);
}
.fixed {
  height: 55vh;
}
</style>
